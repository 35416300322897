var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-center navigator fixed-navigator"},[_c('button',{class:[
        { 'navigator-button-active': _vm.participantShow },
        { 'navigator-button-inactive': !_vm.participantShow },
        'navigator-participant-button' ],on:{"click":_vm.selectParticipantMenu}},[_vm._v(" Participants ")]),_c('button',{class:[
        { 'navigator-button-active': _vm.presentationShow },
        { 'navigator-button-inactive': !_vm.presentationShow },
        'navigator-presentation-button' ],on:{"click":_vm.selectPresentationMenu}},[_vm._v(" Presentation ")]),_c('button',{class:[
        { 'navigator-button-active': _vm.chatShow },
        { 'navigator-button-inactive': !_vm.chatShow },
        'navigator-chat-button' ],on:{"click":_vm.selectChatMenu}},[_vm._v(" Chat ")])]),(_vm.presentationShow)?_c('div',{staticClass:"d-flex justify-content-center navigator"},[_c('button',{class:[
        { 'button-toggle-setting': _vm.handactive && !_vm.gestureLoading },
        { 'navigator-toggle-inactive': !_vm.handactive || _vm.gestureLoading },
        { gestureNotWorking: _vm.gestureNotWorking },
        'navigator-Gesture-button' ],on:{"click":_vm.handAct}},[(!_vm.gestureLoading)?_c('div',[_vm._v("Gesture")]):_vm._e(),_c('LoadingSpinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.gestureLoading),expression:"gestureLoading"}],staticClass:"load-spinner",attrs:{"color":"#15182a"}})],1),_c('button',{class:[
        { 'button-toggle-setting': _vm.ScriptShow },
        { 'navigator-toggle-inactive': !_vm.ScriptShow },
        'navigator-script-button' ],on:{"click":_vm.selectScriptMenu}},[_vm._v(" Script ")])]):_vm._e(),(_vm.participantShow)?_c('MeetingParticipants',{staticClass:"meeting-participants"}):_vm._e(),(_vm.presentationShow)?_c('PresentationController',{staticClass:"presentation-controller"}):_vm._e(),(_vm.chatShow)?_c('Chat',{staticClass:"chat",attrs:{"messageList":_vm.messageList}}):_vm._e(),_c('Hand',{staticClass:"hand",on:{"isLoading":_vm.changeGestureLoading,"gestureNotWorking":_vm.setGestureNotWorking},model:{value:(_vm.handactive),callback:function ($$v) {_vm.handactive=$$v},expression:"handactive"}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.alertShow)?_c('div',{staticClass:"access-alert"},[_c('h5',{staticClass:"mb-0"},[_vm._v("발표자만 사용할 수 있습니다.")])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }