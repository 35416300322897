import { render, staticRenderFns } from "./AddPPTModal.vue?vue&type=template&id=917c70f6&scoped=true&"
import script from "./AddPPTModal.vue?vue&type=script&lang=js&"
export * from "./AddPPTModal.vue?vue&type=script&lang=js&"
import style0 from "./AddPPTModal.vue?vue&type=style&index=0&id=917c70f6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "917c70f6",
  null
  
)

export default component.exports