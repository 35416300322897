<template>
  <footer class="footer p-0">
    <div class="row align-items-center text-center justify-content-center">
      <div class="col-lg-6">
        <div class="copyright text-center text-sm text-muted text-global">
          Copyright 2021. NorthernPlanet in SSAFY 5th. All Rights Reserved.<br />
          Designed by
          <a
            href="https://www.creative-tim.com"
            class="font-weight-bold"
            target="_blank"
            >Creative Tim</a
          >
          (Soft UI) for a better web.
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>
<style scoped>
.text-global {
  color: #505753 !important;
}
</style>
