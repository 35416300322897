var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[_c('div',{staticClass:"button-setting"},[_c('div',{staticClass:"border-setting",on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }return _vm.selectTop.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"])){ return null; }if('button' in $event && $event.button !== 2){ return null; }return _vm.selectRight.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }return _vm.selectLeft.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==49){ return null; }return _vm.selectSize(0)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==50){ return null; }return _vm.selectSize(1)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==51){ return null; }return _vm.selectSize(2)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==52){ return null; }return _vm.selectSize(3)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==53){ return null; }return _vm.selectSize(4)}]}},[_c('h2',{staticClass:"text-center"},[_vm._v("location")])]),_c('div',{staticClass:"template-container"},[_c('img',{class:[
          { 'insert-border': _vm.presentationLocation === 'right' },
          'template-insert',
          'img-fluid' ],attrs:{"src":require("@/assets/presentationTemplates/presentation-right.jpg"),"alt":""}}),_c('div',{staticClass:"overlay",on:{"click":_vm.selectRight}},[(_vm.presentationLocation === 'right')?_c('span',[_vm._v("selected")]):_c('span',[_vm._v("Presentation On Right")])])]),_c('div',{staticClass:"template-container"},[_c('img',{class:[
          { 'insert-border': _vm.presentationLocation === 'left' },
          'template-insert',
          'img-fluid' ],attrs:{"src":require("@/assets/presentationTemplates/presentation-left.jpg"),"alt":""}}),_c('div',{staticClass:"overlay",on:{"click":_vm.selectLeft}},[(_vm.presentationLocation === 'left')?_c('span',[_vm._v("selected")]):_c('span',[_vm._v("Presentation On Left")])])]),_c('div',{staticClass:"template-container"},[_c('img',{class:[
          { 'insert-border': _vm.presentationLocation === 'top' },
          'template-insert',
          'img-fluid' ],attrs:{"src":require("@/assets/presentationTemplates/presentation-top.jpg"),"alt":""}}),_c('div',{staticClass:"overlay",on:{"click":_vm.selectTop}},[(_vm.presentationLocation === 'top')?_c('span',[_vm._v("selected")]):_c('span',[_vm._v("Presentation On Top")])])]),_c('div',{staticClass:"size-controller"},[_c('h2',{staticClass:"text-center"},[_vm._v("size")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedSize),expression:"selectedSize"}],staticClass:"range-select size-class",attrs:{"type":"range","min":"0","max":"4","step":"1"},domProps:{"value":(_vm.selectedSize)},on:{"__r":function($event){_vm.selectedSize=$event.target.value}}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }