import { render, staticRenderFns } from "./RoomReadyModal.vue?vue&type=template&id=53b75138&scoped=true&"
import script from "./RoomReadyModal.vue?vue&type=script&lang=js&"
export * from "./RoomReadyModal.vue?vue&type=script&lang=js&"
import style0 from "./RoomReadyModal.vue?vue&type=style&index=0&id=53b75138&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53b75138",
  null
  
)

export default component.exports