var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":'RoomReadyModal' + _vm.roomInfo.roomId,"tabindex":"-1","role":"dialog","aria-labelledby":"exampleModalMessageTitle","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered text-lg",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":'RoomReadyModal' + _vm.roomInfo.roomId}},[_vm._v(" "+_vm._s(_vm.roomName)+" ")]),_vm._m(0)]),_c('div',{staticClass:"modal-body"},[_c('form',[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label",attrs:{"for":"message-text"}},[_vm._v("Room Description: "),_c('p',[_vm._v(_vm._s(_vm.roomDescription))])])])]),_c('video',{attrs:{"width":"100%","id":'local-video' + _vm.roomId,"autoplay":"true","poster":require("@/assets/img/logos/focus_camera3.jpg")}}),_c('i',{staticClass:"bi bi-mic-fill"}),_c('button',{staticClass:"btn",class:{
            'button-color-setting': _vm.isMicOn,
            'btn-outline-dark': !_vm.isMicOn,
          },attrs:{"type":"button"},on:{"click":_vm.micOnOff}},[_c('span',{staticClass:"fas",class:{
              'fa-microphone': _vm.isMicOn,
              'fa-microphone-slash': !_vm.isMicOn,
            }})]),_c('button',{staticClass:"btn",class:{
            'button-color-setting': _vm.isVideoOn,
            'btn-outline-dark': !_vm.isVideoOn,
          },attrs:{"type":"button"},on:{"click":_vm.videoOnOff}},[_c('span',{staticClass:"fas fa-video",class:{
              'fa-video': _vm.isVideoOn,
              'fa-video-slash': !_vm.isVideoOn,
            }})])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-outline-dark",attrs:{"type":"button","data-bs-dismiss":"modal"}},[_vm._v(" Close ")]),_c('button',{staticClass:"btn button-create-setting",attrs:{"type":"button","data-bs-dismiss":"modal"},on:{"click":_vm.sendMsgToKurento}},[_vm._v(" Join ")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn-close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])}]

export { render, staticRenderFns }