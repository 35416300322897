var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-center navigator"},[_c('button',{class:[
        { 'button-setting': _vm.handactive },
        { 'navigator-button-inactive': !_vm.handactive },
        { gestureNotWorking: _vm.gestureNotWorking },
        'navigator-Gesture-button' ],on:{"click":_vm.handAct}},[(!_vm.gestureLoading)?_c('div',[_vm._v("Gesture")]):_vm._e(),_c('LoadingSpinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.gestureLoading),expression:"gestureLoading"}],staticClass:"load-spinner",attrs:{"color":"#15182a"}})],1),_c('button',{class:[
        { 'button-setting': _vm.ScriptShow },
        { 'navigator-button-inactive': !_vm.ScriptShow },
        'navigator-script-button' ],on:{"click":_vm.selectScriptMenu}},[_vm._v(" Script ")])]),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"small-img-container"},[_c('span',[(_vm.sidebaridx == 1)?_c('img',{staticClass:"small-img-setting",attrs:{"src":require("@/assets/presentationTemplates/first-slide.png"),"alt":"prev_image"}}):(_vm.sidebaridx > 1)?_c('img',{staticClass:"small-img-setting",attrs:{"src":_vm.slideList[_vm.sidebaridx - 1].slideFile,"alt":"prev_image"},on:{"click":_vm.prevImage}}):_vm._e()]),_c('span',[(_vm.sidebaridx == _vm.slideList.length - 2)?_c('img',{staticClass:"small-img-setting",attrs:{"src":require("@/assets/presentationTemplates/last-slide.png"),"alt":"next_image"}}):(_vm.sidebaridx < _vm.slideList.length - 2)?_c('img',{staticClass:"small-img-setting",attrs:{"src":_vm.slideList[_vm.sidebaridx + 1].slideFile,"alt":"next_image"},on:{"click":_vm.nextImage}}):_vm._e()]),_c('span')]),(_vm.LocationSizeShow)?_c('div',[_c('div',[_c('h3',{staticStyle:{"margin":"0px"}},[_vm._v("Location")]),_c('div',{staticClass:"template-container"},[_c('div',{staticClass:"overlay",on:{"click":_vm.selectRight}},[_c('img',{class:[
                { 'insert-border': _vm.selectedLocation === 'right' },
                'template-insert',
                'img-fluid',
                'img-location' ],attrs:{"src":require("@/assets/presentationTemplates/presentation-right.jpg"),"alt":""}}),(_vm.selectedLocation === 'right')?_c('span',[_vm._v("selected")]):_c('span',[_vm._v("Presentation On Right")])])]),_c('div',{staticClass:"template-container"},[_c('div',{staticClass:"overlay",on:{"click":_vm.selectLeft}},[_c('img',{class:[
                { 'insert-border': _vm.selectedLocation === 'left' },
                'template-insert',
                'img-fluid',
                'img-location' ],attrs:{"src":require("@/assets/presentationTemplates/presentation-left.jpg"),"alt":""}}),(_vm.selectedLocation === 'left')?_c('span',[_vm._v("selected")]):_c('span',[_vm._v("Presentation On Left")])])]),_c('div',{staticClass:"template-container"},[_c('div',{staticClass:"overlay",on:{"click":_vm.selectTop}},[_c('img',{class:[
                { 'insert-border': _vm.selectedLocation === 'top' },
                'template-insert',
                'img-fluid',
                'img-location' ],attrs:{"src":require("@/assets/presentationTemplates/presentation-top.jpg"),"alt":""}}),(_vm.selectedLocation === 'top')?_c('span',[_vm._v("selected")]):_c('span',[_vm._v("Presentation On Top")])])])]),_c('div',[_c('h2',{staticClass:"text-global"},[_vm._v("size")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedSize),expression:"selectedSize"}],staticClass:"range-select size-class",attrs:{"type":"range","min":"0","max":"4","step":"1"},domProps:{"value":(_vm.selectedSize)},on:{"__r":function($event){_vm.selectedSize=$event.target.value}}})])]):_c('div',[_c('div',{staticClass:"head-animation"},_vm._l((_vm.effects),function(effect,index){return _c('div',{key:index,class:['effect-container'],attrs:{"id":index},on:{"click":function($event){return _vm.showExample(effect, index)}}},[_c('h6',[_vm._v(_vm._s(index))])])}),0)]),_c('div',{staticClass:"d-flex justify-content-center navigator"},[_c('button',{class:[
          { 'button-setting': _vm.LocationSizeShow },
          { 'navigator-button-inactive': !_vm.LocationSizeShow },
          'navigator-Gesture-button' ],on:{"click":_vm.selectLocationSizeMenu}},[_vm._v(" Location/Size ")]),_c('button',{class:[
          { 'button-setting': !_vm.LocationSizeShow },
          { 'navigator-button-inactive': _vm.LocationSizeShow },
          'navigator-script-button' ],on:{"click":_vm.selectEffectMenu}},[_vm._v(" Effect ")])])]),_c('Hand',{staticClass:"hand",on:{"isLoading":_vm.changeGestureLoading,"next":_vm.nextImage,"locationTop":_vm.selectTop,"locationRight":_vm.selectRight,"sizeUp":_vm.sizeUp,"sizeDown":_vm.sizeDown,"gestureNotWorking":_vm.setGestureNotWorking},model:{value:(_vm.handactive),callback:function ($$v) {_vm.handactive=$$v},expression:"handactive"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }