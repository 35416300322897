var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding-bottom":"70px"}},[(_vm.contentShow)?_c('PresentationContentSelector'):_vm._e(),(_vm.locationShow)?_c('PresentationLocationSelector'):_vm._e(),(_vm.effectShow)?_c('PresentationEffectSelector'):_vm._e(),(_vm.sliderShow)?_c('PresentationSlider'):_vm._e(),_c('div',{staticClass:"prsent-control-navigator"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',[_c('button',{class:[
            { 'navigator-button-active': _vm.contentShow },
            { 'navigator-button-inactive': !_vm.contentShow },
            'navigator-button',
            'navigator-button-start' ],on:{"click":_vm.selectContentMenu}},[_vm._v(" Content ")]),_c('button',{class:[
            { 'navigator-button-active': _vm.locationShow },
            { 'navigator-button-inactive': !_vm.locationShow },
            'navigator-button' ],on:{"click":_vm.selectLocationMenu}},[_vm._v(" Location ")])]),_c('div',[_c('button',{class:[
            { 'navigator-button-active': _vm.effectShow },
            { 'navigator-button-inactive': !_vm.effectShow },
            'navigator-button' ],on:{"click":_vm.selectEffectMenu}},[_vm._v(" Effect ")]),_c('button',{class:[
            { 'navigator-button-active': _vm.sliderShow },
            { 'navigator-button-inactive': !_vm.sliderShow },
            'navigator-button',
            'navigator-button-end' ],on:{"click":_vm.selectSliderMenu}},[_vm._v(" Slider ")])])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.alertShow)?_c('div',{staticClass:"access-alert"},[_c('h5',{staticClass:"mb-0"},[_vm._v("발표자료를 선택하세요.")])]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }