<template>
  <div>
    <IntroPage />
  </div>
</template>
<script>
import IntroPage from '@/views/intro/IntroPage.vue';
export default {
  name: 'App',
  components: { IntroPage },
};
</script>
