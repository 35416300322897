var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"meetingroom-container"},[_c('transition',{attrs:{"name":"left-slide"}},[(_vm.leftSideShow)?_c('VideoUnitGroup',{staticClass:"left-side-bar"}):_vm._e()],1),_c('div',{class:[
      { 'main-left-margin': _vm.leftSideShow },
      { 'main-right-margin': _vm.rightSideShow },
      'main',
      'd-flex',
      'flex-column',
      'align-items-center',
      'justify-content-center' ]},[_c('h1',{staticClass:"room-title"},[_vm._v(_vm._s(_vm.roomTitle))]),_c('MainVideoUnit',{key:_vm.mainParticipant.name,staticClass:"main-video-unit",attrs:{"mainParticipant":_vm.mainParticipant}}),_c('MeetingController',{staticStyle:{"margin-top":"25px"}}),_c('transition',{attrs:{"name":"button-show"}},[(_vm.rightSideShow)?_c('img',{class:[
          { 'button-right-margin': _vm.rightSideShow },
          'right-side-toggler' ],attrs:{"src":require("@/assets/icons/right.svg"),"alt":"right side bar toggle button"},on:{"click":_vm.toggleRightSide}}):_vm._e()]),_c('transition',{attrs:{"name":"button-show"}},[(!_vm.rightSideShow)?_c('img',{staticClass:"right-side-toggler",attrs:{"src":require("@/assets/icons/left.svg"),"alt":""},on:{"click":_vm.toggleRightSide}}):_vm._e()])],1),_c('transition',{attrs:{"name":"right-slide"}},[(_vm.rightSideShow)?_c('MeetingSideBar',{staticClass:"right-side-bar"}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }