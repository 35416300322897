var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',[_c('div',{staticClass:"container py-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"\n            col-xl-7 col-lg-10\n            mx-auto\n            d-flex\n            justify-content-center\n            flex-column\n            px-10\n          "},[_c('div',{staticClass:"header"},[_c('h2',{staticClass:"title text-center"},[_vm._v(" "+_vm._s(this.roomId)+" - "+_vm._s(_vm.roomName)+" Room Ready ")])]),_c('div',{staticClass:"body"},[_c('form',[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label",attrs:{"for":"message-text"}},[_vm._v("Room Description: "),_c('p',[_vm._v(_vm._s(_vm.roomDescription))])])]),_c('div',{staticClass:"form-group mb-4"},[_vm._m(0),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.userName),expression:"userName"}],staticClass:"form-control",attrs:{"type":"text","rows":"1","placeholder":"이름을 적어주세요."},domProps:{"value":(_vm.userName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.userName=$event.target.value}}})])]),_c('video',{attrs:{"width":"100%","id":'local-video' + _vm.roomId,"autoplay":"true","poster":require("@/assets/img/logos/focus_camera3.jpg")}}),_c('i',{staticClass:"bi bi-mic-fill"}),_c('button',{staticClass:"btn",class:{
                'button-color-setting': _vm.isMicOn,
                'btn-outline-dark': !_vm.isMicOn,
              },attrs:{"type":"button"},on:{"click":_vm.micOnOff}},[_c('span',{staticClass:"fas",class:{
                  'fa-microphone': _vm.isMicOn,
                  'fa-microphone-slash': !_vm.isMicOn,
                }})]),_c('button',{staticClass:"btn",class:{
                'button-color-setting': _vm.isVideoOn,
                'btn-outline-dark': !_vm.isVideoOn,
              },attrs:{"type":"button"},on:{"click":_vm.videoOnOff}},[_c('span',{staticClass:"fas fa-video",class:{
                  'fa-video': _vm.isVideoOn,
                  'fa-video-slash': !_vm.isVideoOn,
                }})]),_c('button',{staticClass:"btn button-create-setting",staticStyle:{"float":"right"},attrs:{"type":"button"},on:{"click":_vm.sendMsgToKurento}},[_vm._v(" Join ")])]),_c('div',{staticClass:"footer"})])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',[_c('h6',[_vm._v("My Name")])])}]

export { render, staticRenderFns }